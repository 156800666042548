import React, { useEffect, useState } from 'react';
import { FaTrash, FaPlus, FaHome, FaCopy, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

const APIKeyManagement = ({ session }) => {
  const [apiKey, setApiKey] = useState(null);
  const [prefix, setPrefix] = useState('');
  const [expiry, setExpiry] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');
  const [copiedMessage, setCopiedMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    getApiKey();
  }, []);

  const generateAPIKey = async () => {
    try {
      const response = await axios.post(`${backendUrl}/connect/api_key`,
        { prefix, expiry },
        { headers: { Authorization: `Bearer ${session.access_token}` } }
      );
      console.log(response.data);
      setNewApiKey(response.data.apiKey);
      setShowModal(true);
    } catch (error) {
      console.error('Error generating API key:', error);
      alert('Error generating API key: ' + error);
    }
  };

  const getApiKey = async () => {
    try {
      const response = await axios.get(`${backendUrl}/connect/api_key`,
        { headers: { Authorization: `Bearer ${session.access_token}` } }
      );
      console.log(response.data);
      if(response.data.apiKey)
        setApiKey(response.data.apiKey);
    } catch (error) {
      console.error('Error getting API key:', error);
      alert('Error getting API key: ' + error);
    }
  };

  const deleteAPIKey = async () => {
    try {
      await axios.delete(`${backendUrl}/connect/api_key`,
        { headers: { Authorization: `Bearer ${session.access_token}` } }
      );
      setApiKey(null);
    } catch (error) {
      console.error('Error deleting API key:', error);
      alert('Error deleting API key: ' + error);
    }
  };

  const handleHomeClick = () => {
    navigate('/notebook');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(newApiKey).then(() => {
      setCopiedMessage('Copied to clipboard!');
      setTimeout(() => setCopiedMessage(''), 3000);
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setNewApiKey(''); // Clear the new API key from memory
    getApiKey(); // Refresh the API key after generating a new one
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-gray-100 shadow-md border-r border-gray-300">
        <div className="p-6">
          <h1 className="text-2xl font-bold text-gray-800">Connections</h1>
        </div>
        <nav className="mt-6">
          <button
            onClick={handleHomeClick}
            className="w-full flex items-center p-4 text-gray-600 hover:bg-purple-100 hover:text-purple-700"
          >
            <FaHome className="mr-4" />
            Go to Notebook
          </button>
        </nav>
      </div>

      <div className="flex-1 p-10 overflow-y-auto">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">API Key Management</h2>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Generate API Key</h3>
          <div className="space-y-4">
            <input
              type="text"
              placeholder="Prefix (optional)"
              value={prefix}
              onChange={(e) => setPrefix(e.target.value)}
              className="w-full p-2 border rounded"
            />
            <input
              type="date"
              placeholder="Expiry (optional)"
              value={expiry}
              onChange={(e) => setExpiry(e.target.value)}
              className="w-full p-2 border rounded"
            />
            <button
              onClick={generateAPIKey}
              className="w-full bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 flex items-center justify-center"
            >
              <FaPlus className="mr-2" /> Generate API Key
            </button>
          </div>
        </div>

        {apiKey && (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Your API Key</h3>
            <div className="flex items-center space-x-4 mb-4">
              <input
                type="text"
                value={apiKey}
                readOnly
                className="flex-1 p-2 border rounded bg-gray-100"
              />
              <button
                onClick={deleteAPIKey}
                className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
              >
                <FaTrash />
              </button>
            </div>
            <p className="text-sm text-gray-600">
              Prefix: {prefix || 'None'} | Expiry: {expiry || 'None'}
            </p>
          </div>
        )}

        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-700">Your New API Key</h3>
                <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                  <FaTimes />
                </button>
              </div>
              <p className="mb-4 text-sm text-gray-600">
                Copy this key now. You won't be able to see it again!
              </p>
              <div className="flex items-center space-x-2 mb-4">
                <input
                  type="text"
                  value={newApiKey}
                  readOnly
                  className="flex-1 p-2 border rounded bg-gray-100"
                />
                <button
                  onClick={copyToClipboard}
                  className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                >
                  <FaCopy />
                </button>
              </div>
              {copiedMessage && (
                <p className="text-green-600 text-sm">{copiedMessage}</p>
              )}
              <button
                onClick={closeModal}
                className="w-full mt-4 bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default APIKeyManagement;