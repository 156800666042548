import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Auth } from "@supabase/auth-ui-react";
import axios from 'axios';
import NotebookEditor from './NotebookEditor';
import ConnectAccount from './ConnectAccount.js';
import APIKeyManagement from "./APIKeyManagement";

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || "";
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || "";
const backendUrl = process.env.REACT_APP_BACKEND_URL || "";
const supabase = createClient(supabaseUrl, supabaseKey);

function App() {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        validateToken(session.access_token).then((valid) => {
          if (!valid) {
            handleLogout();
          } else {
            setUser(session.user);
            setSession(session);
          }
        });
      }
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        validateToken(session.access_token).then((valid) => {
          if (!valid) {
            handleLogout();
          } else {
            setUser(session.user);
            setSession(session);
          }
        });
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const validateToken = async (token) => {
    try {
      const response = await axios.get(`${backendUrl}/ping`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.valid) {
        console.log('Token is valid');
        return true;
      } else {
        console.log('Token is invalid');
        alert('Invalid Token: Your session has expired. Please log in again.');
        return false;
      }
    } catch (error) {
      console.error('Error validating token:', error);
      alert('Something went wrong');
      return false;
    }
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Error logging out:', error.message);
    setUser(null);
    setSession(null);
  };

  return (
    <Router>
      <div className="App" style={{ height: '100vh' }}>
        {user ? (
          <Routes>
            <Route path="/notebook" element={<NotebookEditor session={session} onLogout={handleLogout} />} />
            <Route path="/manage" element={<APIKeyManagement session={session} onLogout={handleLogout} />} />
            <Route path="/connect" element={<ConnectAccount session={session} />} />
            <Route path="*" element={<Navigate to="/notebook" replace />} />
          </Routes>
        ) : (
          <div className="flex h-screen w-full items-center justify-center">
            <Auth
              supabaseClient={supabase}
              appearance={{ theme: ThemeSupa }}
              providers={[]}
            />
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;