import React, {useState, useCallback, useEffect, useRef} from 'react';
import ReactFlow, { 
  Background, 
  Controls, 
  MiniMap,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';
import { ChevronRight, ChevronLeft, Play, Pause, SkipForward, SkipBack, Send, Plus } from 'lucide-react';
import { Highlight, themes } from 'prism-react-renderer';
import {useNavigate} from "react-router-dom";
import {FaHome, FaPlus, FaRocket, FaTimes} from "react-icons/fa";
const backendUrl = process.env.REACT_APP_BACKEND_URL || "";
const ToolNode = ({ data }) => (
  <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-blue-500">
    <div className="font-bold text-blue-500">{data.label}</div>
  </div>
);

const LogicNode = ({ data }) => (
  <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-green-500">
    <div className="font-bold text-green-500">{data.label}</div>
  </div>
);

const LLMNode = ({ data }) => (
  <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-purple-500">
    <div className="font-bold text-purple-500">{data.label}</div>
  </div>
);

const nodeTypes = {
  tool: ToolNode,
  logic: LogicNode,
  llm: LLMNode,
};

const edgeOptions = {
  animated: true,
  style: {
    stroke: '#888',
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: '#888',
  },
};

const mockApiCall = async (session, content, executionId, is_execution=false, title= '') => {
  // Add an actual api call here
  let url = backendUrl + '/build/' + executionId
  if(is_execution) {
    url += '/test'
  }
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${session.access_token}`
      },
      body: JSON.stringify({ message: content, title: title }),
    });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();


  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ nodes: data["result"]["nodes"], edges: data["result"]["edges"], code: data["result"]["code"],
        current_state: data["current_state"], response: data["result"]});
    }, 1000);
  });
};

const fetchTabs = async (session) => {
    try {
      let url = backendUrl + '/projects/'

      // Replace with your actual API call
      const response = await fetch(url, {
        headers: {
         Authorization: `Bearer ${session.access_token}`
      },
      });
      return response.json();
    } catch (error) {
      console.error('Error fetching connections:', error);
      return {}
    }
  };

const fetchTabData = async (session, executionId) => {
    try {
      let url = backendUrl + '/build/' + executionId + '/data'

      // Replace with your actual API call
      const response = await fetch(url, {
        headers: {
         Authorization: `Bearer ${session.access_token}`
      },
      });
      return response.json();
    } catch (error) {
      console.error('Error fetching connections:', error);
      return {}
    }
  };

const formatJsonInMessage = (message) => {
  const jsonRegex = /{[\s\S]*}/;
  const match = message.match(jsonRegex);
  if (match) {
    try {
      const jsonPart = JSON.parse(match[0]);
      const formattedJson = JSON.stringify(jsonPart, null, 2);
      return message.replace(jsonRegex, 
        `<pre class="bg-gray-800 text-white p-2 rounded mt-2 overflow-auto max-h-60"><code>${formattedJson}</code></pre>`
      );
    } catch (e) {
    }
  }
  try {
    return formatNumberedMessage(message)
  } catch (e) {
  }
  return message;
};

const formatNumberedMessage = (message) => {
  const lines = message.split('\n');
  let formattedMessage = '';
  let inNumberedList = false;

  lines.forEach((line, index) => {
    if (line.match(/^\d+\./)) {
      if (!inNumberedList) {
        formattedMessage += '<ol class="bg-gray-800 text-white p-2 rounded mt-2 overflow-auto max-h-60 list-decimal list-inside">';
        inNumberedList = true;
      }
      formattedMessage += `<li>${line.replace(/^\d+\./, '').trim()}</li>`;
    } else {
      if (inNumberedList) {
        formattedMessage += '</ol>';
        inNumberedList = false;
      }
      formattedMessage += `<p>${line}</p>`;
    }
  });

  if (inNumberedList) {
    formattedMessage += '</ol>';
  }

  return formattedMessage;
};

const ChatMessage = ({ message, isUser }) => (
  <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-2`}>
    <div 
      className={`max-w-[70%] p-2 rounded-lg ${isUser ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
    >
      {isUser ? (
        <p>{message}</p>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: formatJsonInMessage(message) }} />
      )}
    </div>
  </div>
);



const ErrorPopup = ({ message, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
      <h3 className="text-xl font-bold text-red-600 mb-4">Error</h3>
      <p className="text-gray-700 mb-4">{message}</p>
      <button
        onClick={onClose}
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
      >
        Close
      </button>
    </div>
  </div>
);

const generateExecutionId = () => {
  return Math.random().toString(36).substr(2, 9);
};

const CodeEditor = ({ value, onChange }) => {
  const [lineNumbers, setLineNumbers] = useState(['1']);
  const textareaRef = useRef(null);

  useEffect(() => {
    const lines = value.split('\n');
    setLineNumbers(lines.map((_, i) => (i + 1).toString()));
  }, [value]);

  const handleTextareaChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="relative font-mono text-sm h-full bg-gray-900 text-gray-200 overflow-hidden">
      <div className="absolute left-0 top-0 bottom-0 w-12 bg-gray-800 text-gray-500 text-right pr-2 select-none overflow-hidden">
        {lineNumbers.map((num, i) => (
          <div key={i}>{num}</div>
        ))}
      </div>
      <div className="absolute inset-0 overflow-auto pl-12">
        <Highlight theme={themes.vsDark} code={value} language="python">
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={`${className} p-4 min-h-full`} style={style}>
              {tokens.map((line, i) => (
                <div key={i} {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
        <textarea
          ref={textareaRef}
          value={value}
          onChange={handleTextareaChange}
          className="absolute inset-0 w-full h-full opacity-0 resize-none outline-none"
          spellCheck="false"
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
          data-gramm="false"
        />
      </div>
    </div>
  );
};

const NotebookEditor = ({ session, onLogout }) => {
  const navigate = useNavigate();
  const [activeEditorTab, setActiveEditorTab] = useState(-1);
  const [activePreviewTab, setActivePreviewTab] = useState(0);
  const [activeCodeTab, setActiveCodeTab] = useState(0);
  const [activeDebugTab, setActiveDebugTab] = useState(0);
  const [rightPanelWidth, setRightPanelWidth] = useState(50);
  const [isResizing, setIsResizing] = useState(false);
  const [editorTabs, setEditorTabs] = useState([]);

  const [isBuildClicked, setIsBuildClicked] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [code, setCode] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  
  const [currentExecutionId, setCurrentExecutionId] = useState('');
  const [activeInputLogTab, setActiveInputLogTab] = useState(0);
  const [code_writer_done, setCodeWriterDone] = useState(false);
  const [codeEditorContent, setCodeEditorContent] = useState(code || '# Your Python code here\n\ndef example_function():\n    print("Hello, World!")');


  const [current_schema, setCurrentSchema] = useState({
    "final_requirements": "Updated Requirements will come here",
    "input": {
      "connected_apps": [],
      "params": [
      ]
    },
    "output": {
    },
    "questions": []
  });
  const [inputValues, setInputValues] = useState({});
  const [outputData, setOutputData] = useState({
      output: null,
      error: null,
      stepInfo: null
    });

  const [showDeployModal, setShowDeployModal] = useState(false);

  const [copiedMessage, setCopiedMessage] = useState('');

  const curlCommand = (executionId) => {

    return `curl --location '${backendUrl}/execute/${executionId}' 
 --header 'Authorization: Bearer <YOUR_API_KEY>' 
 --header 'Content-Type: application/json' 
 --data '{
     // Your json data
    }'`;
  }

  const copyToClipboard = (text) => {
   navigator.clipboard.writeText(text).then(() => {
      setCopiedMessage('Copied to clipboard!');
      setTimeout(() => setCopiedMessage(''), 3000);
    });
  };


  const handleDeployClick = () => {
     setShowDeployModal(true);
  };

  const closeDeployModal = () => {
    setShowDeployModal(false);
  };


  useEffect(() => {
  // Fetch connections from API when component mounts
    fetchTabs(session).then((data) => {
      let tabsData = []
      Object.keys(data).forEach(executionId => {
        try {
          const jsonObj = JSON.parse(data[executionId])
          tabsData.push({
            title: jsonObj["title"],
            content: jsonObj["content"].split("Build the workflow for: ")[1],
            executionId: executionId
          })
        } catch (e) {
          console.error("Error parsing json" + data[executionId])
        }
      });
      setEditorTabs(tabsData);
      if (tabsData.length > 0) {
        setCurrentExecutionId(tabsData[0].executionId);
        setActiveEditorTab(0);
      }
      else {
        addNewTab("My First Workflow")
      }
    });
  }, []);

    const handleConnectAccount = () => {
      navigate('/connect');
    };

    const handleManageAccount = () => {
      navigate('/manage');
    };

    const handleInputChange = (paramName, value, index = null) => {
    setInputValues(prevValues => {
      if (index !== null) {
        const newList = [...(prevValues[paramName] || [])];
        newList[index] = value;
        return { ...prevValues, [paramName]: newList };
      }
      return { ...prevValues, [paramName]: value };
    });
  };

  const handleAddListItem = (paramName) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [paramName]: [...(prevValues[paramName] || []), '']
    }));
  };

  const handleCodeChange = (newCode) => {
    setCodeEditorContent(newCode);
  };

  const handleSaveCode = () => {
    setCode(codeEditorContent);
    // Here you can add logic to save the code to your backend or perform other actions
    console.log('Code saved:', codeEditorContent);
  };



    const renderInputForm = () => {
    return (
        <div className="space-y-4">
          <div>
            <h3 className="font-bold mb-2">Final Requirements:</h3>
            <p className="text-sm">{current_schema.final_requirements}</p>
          </div>

          <div>
            <h3 className="font-bold mb-2">Connected Apps:</h3>
            <div className="flex space-x-2">
              {current_schema.input.connected_apps.map((app, index) => (
                  <button
                      key={index}
                      className="px-3 py-1 bg-blue-500 text-white rounded-md text-sm"
                  >
                    {app}
                  </button>
              ))}
            </div>
          </div>

          <div>
            <h3 className="font-bold mb-2">Input Parameters:</h3>
            {current_schema.input.params.map((param, index) => (
                <div key={index} className="mb-4">
                  <label className="block text-sm font-medium mb-1">{param.name}:</label>
                  {param.is_list ? (
                      <div>
                        {(inputValues[param.name] || ['']).map((value, i) => (
                            <div key={i} className="flex items-center mb-2">
                              <input
                                  type="text"
                                  value={value}
                                  onChange={(e) => handleInputChange(param.name, e.target.value, i)}
                                  className="flex-grow px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                              />
                              {i === (inputValues[param.name] || ['']).length - 1 && (
                                  <button
                                      onClick={() => handleAddListItem(param.name)}
                                      className="ml-2 p-1 bg-green-500 text-white rounded-md"
                                  >
                                    <Plus size={16}/>
                                  </button>
                              )}
                            </div>
                        ))}
                      </div>
                  ) : (
                      <input
                          type="text"
                          value={inputValues[param.name] || ''}
                          onChange={(e) => handleInputChange(param.name, e.target.value)}
                          className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                  )}
                </div>
            ))}
          </div>
          <button
              onClick={handleExecute}
              className={`w-full px-4 py-2 text-white rounded-md transition-colors ${
                  code_writer_done && !isProcessing
                      ? 'bg-green-600 hover:bg-green-700'
                      : 'bg-gray-400 cursor-not-allowed'
              }`}
              disabled={!code_writer_done || isProcessing}
          >
            {isProcessing ? 'Executing...' : 'Execute'}
          </button>

        </div>
    );
    };


  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const previewTabs = ['Visual Flow', 'Code', 'Debug and Run'];
  const renderInputLogs = () => (
    <div className="border-t border-gray-300 flex flex-col h-full">
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 ${activeInputLogTab === 0 ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveInputLogTab(0)}
        >
          Inputs
        </button>
        <button
          className={`px-4 py-2 ${activeInputLogTab === 1 ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveInputLogTab(1)}
        >
          Output
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        {activeInputLogTab === 0 ? renderInputForm() : renderOutput()}
      </div>
    </div>
  );
  const renderOutput = () => {
  return (
    <div className="space-y-4 h-full overflow-y-auto p-4">
      {outputData.error && (
        <div className="p-4 bg-red-100 border border-red-400 rounded-md">
          <h4 className="text-red-700 font-bold mb-2">Error:</h4>
          <pre className="whitespace-pre-wrap overflow-x-auto">
            {typeof outputData.error === 'string' ? outputData.error : JSON.stringify(outputData.error, null, 2)}
          </pre>
        </div>
      )}
      {outputData.output && (
        <div className="p-4 bg-green-100 border border-green-300 rounded-md">
          <h4 className="font-bold mb-2">Output:</h4>
          <pre className="whitespace-pre-wrap overflow-x-auto">
            {typeof outputData.output === 'string' ? outputData.output : JSON.stringify(outputData.output, null, 2)}
          </pre>
        </div>
      )}
      {outputData.stepInfo && (
        <div className="p-4 bg-gray-100 border border-gray-300 rounded-md">
          <h4 className="font-bold mb-2">Step Information:</h4>
          <pre className="whitespace-pre-wrap overflow-x-auto">
            {JSON.stringify(outputData.stepInfo, null, 2)}
          </pre>
        </div>
      )}
      {!outputData.error && !outputData.stepInfo && !outputData.output && (
        <div className="p-4 bg-gray-100 border border-gray-300 rounded-md">
          <p>No output data available yet. Run the workflow to see results here.</p>
        </div>
      )}
    </div>
  );
};

  const handleRun = () => {
    setIsRunning(true);
  };

  const closeErrorPopup = () => {
    setError(null);
  };

  const handlePause = () => {
    setIsRunning(false);
  };

  const handleStepAhead = () => {
    // Implement step ahead logic
    console.log('Step ahead');
  };

  const handleStepBack = () => {
    // Implement step back logic
    console.log('Step back');
  };

  const handleEditorTabClick = (index) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('Do you really want to switch to tab' + index + '?')) {
      // TODO Save the content of current tab
      setActiveEditorTab(index);
      setCurrentExecutionId(editorTabs[index].executionId);
      cleanupView()
      fetchTabData(session, editorTabs[index].executionId).then((data) => {
        console.log(data)
        if(data.schema)
          parseApiResponse({current_state: data.schema.current_state, response: data.schema.result})
        if(data.plan)
          parseApiResponse({nodes: data.plan.result.nodes, edges: data.plan.result.edges,
            code: data.plan.result.code, current_state: data.plan.current_state, response: data.plan.result})
          })
      // Fetch the content of the new tab
    }
  };

  const handlePreviewTabClick = (index) => {
    setActivePreviewTab(index);
  };

  const handleCodeTabClick = (index) => {
    setActiveCodeTab(index);
  };

  const handleDebugTabClick = (index) => {
    setActiveDebugTab(index);
  };

  const handleContentChange = (e) => {
    const newTabs = [...editorTabs];
    newTabs[activeEditorTab].content = e.target.value;
    setEditorTabs(newTabs);
  };

  const cleanupView = () => {
    setCodeEditorContent('');
    setInputValues({})
    setOutputData({
      output: null,
      error: null,
      stepInfo: null
    })
    setCurrentSchema({
    "final_requirements": "Updated Requirements will come here",
    "input": {
      "connected_apps": [],
      "params": [
      ]
    },
    "output": {
    },
    "questions": []
  })
    setChatMessages([])
    setNodes([])
    setEdges([])
    setCode('')
    setActiveCodeTab(0)
    setActivePreviewTab(0)
    setActiveDebugTab(0)
    setActiveInputLogTab(0)
  }

  const addNewTab = (tabName='') => {
    if (!tabName)
      tabName = prompt("Enter the name for the new workflow:");
    if (!tabName) return;
    const newTab = {
      title: tabName,
      content: '',
      executionId: generateExecutionId()
    };
    setEditorTabs([...editorTabs, newTab]);
    cleanupView()
    setActiveEditorTab(editorTabs.length);
    setCurrentExecutionId(newTab.executionId);
  };


  const handleResize = useCallback((e) => {
    if (isResizing) {
      const newWidth = (e.clientX / window.innerWidth) * 100;
      setRightPanelWidth(100 - newWidth);
    }
  }, [isResizing]);

  const startResize = () => {
    setIsResizing(true);
  };

  const stopResize = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleResize);
      window.addEventListener('mouseup', stopResize);
    } else {
      window.removeEventListener('mousemove', handleResize);
      window.removeEventListener('mouseup', stopResize);
    }
    return () => {
      window.removeEventListener('mousemove', handleResize);
      window.removeEventListener('mouseup', stopResize);
    };
  }, [isResizing, handleResize]);


  const parseApiResponse = (result) => {
    if (typeof result.response !== 'string') {
      if (result.response.final_requirements) {
        setCurrentSchema(result.response);
      } else if (result.response.code) {
        setCodeWriterDone(true);
        try {
          let responseMessage = "";
          if (result.nodes.length > 0 || result.edges.length > 0) {
            responseMessage += "I've updated the flow diagram with new nodes and edges. ";
            setNodes(result.nodes);
            setEdges(result.edges);
          }

          if (result.code) {
            responseMessage += "Here's the generated code:\n\n```\n" + result.code + "\n```";
            setCode(result.code);
            handleCodeChange(result.code);
            handleSaveCode();
          }

          if (result.nodes.length > 0 || result.edges.length > 0) {
            setActivePreviewTab(0);
          } else if (result.code) {
            setActivePreviewTab(1);
          }
        } catch (e) {
          setError(e);
        }
      }

      if (result.current_state === "Executor") {
        setOutputData({
          output: result.response.output,
          error: result.response.error,
          stepInfo: result.response.step_info
        });
        setActiveInputLogTab(1); // Switch to Output tab
      }
    }

    let responseMessage = "Here's what I generated based on your input:\n\n";
    responseMessage += `Current state is: ${result.current_state}\n\n`;
    if (result.response.questions != null) {
      responseMessage += result.response.questions;
    }
    else if (typeof result.response === 'string') {
      responseMessage += result.response;
    } else {
      responseMessage += JSON.stringify(result.response, null, 2);
    }

    setChatMessages(prev => [...prev, { text: responseMessage, isUser: false }]);
  };



  const handleBuild = async (content, is_execution=false) => {
    setIsProcessing(true);
    setIsBuildClicked(true);
    try {
      const result = await mockApiCall(session, content, currentExecutionId, is_execution, editorTabs[activeEditorTab].title);
      parseApiResponse(result)
    } catch (error) {
      console.error('Error processing message:', error);
      setChatMessages(prev => [...prev, { text: `Error occured while processing your message: ${error}`, isUser: false }]);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleExecute = () => {
    if (!code_writer_done) return;
    handleBuild(inputValues, true);
  };

  const handleSendMessage = async () => {
    if (chatInput.trim() && !isProcessing) {
      setIsProcessing(true);
      const userMessage = chatInput.trim();
      setChatMessages(prev => [...prev, { text: userMessage, isUser: true }]);
      setChatInput('');

      try {
        const result = await mockApiCall(session, userMessage, currentExecutionId);

        parseApiResponse(result)

      } catch (error) {
        console.error('Error processing message:', error);
        setChatMessages(prev => [...prev, { text: `Error occured while processing your message: ${error}`, isUser: false }]);
      } finally {
        setIsProcessing(false);
      }
    }
  };


  const renderPreviewContent = () => {
    switch (activePreviewTab) {
      case 0:
        return (
            <div className="h-full flex flex-col">
              <div className="flex-grow">
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    edgeOptions={edgeOptions}
                    fitView
                >
                  <Background/>
                  <Controls/>
                  <MiniMap/>
                </ReactFlow>
              </div>
            </div>
        );
        case 1:
          return (
            <div className="h-full flex flex-col">
              <div className="flex border-b border-gray-700">
                <button
                  className={`px-4 py-2 ${activeCodeTab === 0 ? 'bg-purple-600 text-white' : 'bg-gray-800 text-gray-300'}`}
                  onClick={() => handleCodeTabClick(0)}
                >
                  Full Code
                </button>
                {nodes.map((node, index) => (
                  <button
                    key={node.id}
                    className={`px-4 py-2 ${activeCodeTab === index + 1 ? 'bg-purple-600 text-white' : 'bg-gray-800 text-gray-300'}`}
                    onClick={() => handleCodeTabClick(index + 1)}
                  >
                    {node.data.label}
                  </button>
                ))}
              </div>
              <div className="flex-1 overflow-hidden">
                <CodeEditor
                  value={activeCodeTab === 0 ? codeEditorContent : `# Code for ${nodes[activeCodeTab - 1]?.data.code || ''}`}
                  onChange={handleCodeChange}
                />
              </div>
              <div className="p-2 border-t border-gray-700 flex justify-between items-center">
                <span className="text-sm text-gray-400">
                  Lines: {codeEditorContent.split('\n').length}
                </span>
              </div>
            </div>
          );

        case 2:
          return (
            <div className="h-full flex flex-col">
              <div className="p-2 border-b border-gray-300 flex justify-start space-x-2">
                {isRunning ? (
                  <button onClick={handlePause} className="p-1 bg-red-500 text-white rounded">
                    <Pause size={20} />
                  </button>
                ) : (
                  <button onClick={handleRun} className="p-1 bg-green-500 text-white rounded">
                    <Play size={20} />
                  </button>
                )}
                <button onClick={handleStepBack} className="p-1 bg-blue-500 text-white rounded">
                  <SkipBack size={20} />
                </button>
                <button onClick={handleStepAhead} className="p-1 bg-blue-500 text-white rounded">
                  <SkipForward size={20} />
                </button>
              </div>
              <div className="flex-grow">
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={nodeTypes}
                  edgeOptions={edgeOptions}
                  fitView
                >
                  <Background />
                  <Controls />
                  <MiniMap />
                </ReactFlow>
              </div>
            </div>
          );
        default:
          return null;
      }
    };

  if (activeEditorTab > -1) {
    return (
        <div className="flex flex-col h-screen">
          <div className="flex flex-1 overflow-hidden">

            {/* Sidebar */}
            <div className="w-64 bg-gray-100 shadow-md border-r border-gray-300">
              <div className="p-6">
                <h1 className="text-2xl font-bold text-gray-800">Workflows</h1>
              </div>

              {/* Tab buttons */}
              <div className="flex flex-col border-b border-gray-300">
                {editorTabs.map((connection, index) => (
                    <button
                        key={index}
                        className={`px-4 py-2 text-left ${activeEditorTab === index ? 'bg-purple-600 text-white' : 'hover:bg-gray-200'}`}
                        onClick={() => handleEditorTabClick(index)}
                    >
                      <span>{connection.title}</span>
                      <span className="ml-2 text-xs opacity-50">{connection.id}</span>
                    </button>
                ))}

                {/* Add new connection button */}
                <button
                    className="px-4 py-2 text-left text-purple-600 hover:bg-gray-200"
                    onClick={() => addNewTab('')}
                >
                  <FaPlus className="inline mr-2"/>
                  Build New Workflow
                </button>
              </div>

              <nav className="mt-6">
                <button
                    onClick={handleConnectAccount}
                    className="w-full flex items-center p-4 text-gray-600 hover:bg-purple-100 hover:text-purple-700"
                >
                  <FaHome className="mr-4"/>
                  Connect Accounts
                </button>
                <button
                    onClick={handleManageAccount}
                    className="w-full flex items-center p-4 text-gray-600 hover:bg-purple-100 hover:text-purple-700"
                >
                  <FaHome className="mr-4"/>
                  Manage Account
                </button>
                <button
                    onClick={onLogout}
                    className="w-full flex items-center p-4 text-gray-600 hover:bg-purple-100 hover:text-purple-700"
                >
                  <FaHome className="mr-4"/>
                  Logout
                </button>
              </nav>
            </div>

            {/* Left side - Notebook Editor */}
            <div className={`flex flex-col border-r border-gray-300 bg-white`}
                 style={{width: `${100 - rightPanelWidth}%`}}>
              {/* Notebook Editor */}
              <div className={`flex flex-col flex-grow ${isBuildClicked ? 'h-[30%]' : 'h-[50%]'}`} overflow-hidden>
              <div className="flex-1 relative overflow-hidden">
            <textarea
                className="flex-1 w-full p-4 text-sm font-mono resize-none focus:outline-none overflow-auto"
                style={{
                  backgroundImage: 'linear-gradient(#e5e7eb 1px, transparent 1px)',
                  backgroundSize: '100% 1.5rem',
                  lineHeight: '1.5rem',
                  paddingTop: '0.25rem',
                  height: '100%',
                }}
                value={editorTabs[activeEditorTab].content}
                onChange={handleContentChange}
            /></div>
                <div className="p-4 border-t border-gray-300 flex justify-between items-center">
                  <button
                      className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
                      onClick={() => handleBuild("Build the workflow for: " + editorTabs[activeEditorTab].content)}
                  >
                    Build
                  </button>
                  <span
                      className="text-sm text-gray-500">Line: {editorTabs[activeEditorTab].content.split('\n').length}</span>
                </div>
              </div>

              {/* Chat Section */}
              <div className={`${isBuildClicked ? 'h-[60%]' : 'h-[40%]'} border-t border-gray-300 flex flex-col`}>
                <div className="flex-1 p-4 overflow-y-auto">
                  {chatMessages.map((msg, index) => (
                      <ChatMessage key={index} message={msg.text} isUser={msg.isUser}/>
                  ))}
                </div>
                <div className="p-2 border-t border-gray-300 flex">
              <textarea
                  className="flex-1 px-2 py-1 border rounded-l-md focus:outline-none resize-none overflow-hidden"
                  value={chatInput}
                  onChange={(e) => {
                    setChatInput(e.target.value);
                    e.target.style.height = 'auto';
                    e.target.style.height = e.target.scrollHeight + 'px';
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    } else if (e.key === 'Enter' && e.shiftKey) {
                      setChatInput(chatInput + '\n');
                    }
                  }}
                  placeholder="Type your message... (Shift+Enter for new line)"
                  disabled={isProcessing}
                  rows={1}
              />
                  <button
                      className={`px-4 py-2 ${isProcessing ? 'bg-gray-400' : 'bg-purple-600 hover:bg-purple-700'} text-white rounded-r-md`}
                      onClick={handleSendMessage}
                      disabled={isProcessing}
                  >
                    {isProcessing ? 'Processing...' : <Send size={20}/>}
                  </button>
                </div>
              </div>
            </div>

            {/* Resizer */}
            <div
                className="w-1 bg-gray-300 cursor-col-resize flex items-center justify-center"
                onMouseDown={startResize}
            >
              {rightPanelWidth > 50 ? <ChevronLeft size={20}/> : <ChevronRight size={20}/>}
            </div>

            {/* Right side - Tabbed Preview */}
            <div className={`flex flex-col`} style={{width: `${rightPanelWidth}%`}}>
              <div className="flex border-b border-gray-300 bg-white justify-between items-center">
                <div className="flex">
                  {previewTabs.map((tab, index) => (
                      <button
                          key={index}
                          className={`px-4 py-2 ${activePreviewTab === index ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
                          onClick={() => handlePreviewTabClick(index)}
                      >
                        {tab}
                      </button>
                  ))}
                </div>
                <div className="px-4 py-2 text-sm text-gray-500">
                  Execution ID: {currentExecutionId}
                </div>
                <div className="bg-white rounded-lg shadow-md p-6">
                   <button
                     onClick={handleDeployClick}
                     className="w-full bg-green-600 text-white px-2 py-1 rounded hover:bg-green-700 flex items-center justify-center"
                   >
               <FaRocket className="mr-2" /> Deploy
               </button>
               </div>

              </div>
              <div className="flex-1 bg-white">
                {renderPreviewContent()}
              </div>
              <div style={{height: `40%`}}>
                {renderInputLogs()}
              </div>
            </div>

            {/* Error Popup */}
            {error && <ErrorPopup message={error} onClose={closeErrorPopup}/>}
          </div>
           {showDeployModal && (
   <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center 	justify-center">
     <div className="bg-white p-8 rounded-lg shadow-xl max-w-2xl w-full">
       <div className="flex justify-between items-center mb-4">
         <h3 className="text-xl font-semibold text-gray-700">Deploy Command</h3>
         <button onClick={closeDeployModal} className="text-gray-500 hover:text-gray-700">
           <FaTimes />
         </button>
       </div>
       <p className="mb-4 text-sm text-gray-600">
         Use this curl command to deploy:
       </p>
       <div className="bg-gray-100 p-4 rounded-lg mb-4 overflow-x-auto">
         <pre className="text-sm">{curlCommand(currentExecutionId)}</pre>
       </div>
       <div className="flex justify-between">
         <button
           onClick={() => copyToClipboard(curlCommand)}
           className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
         >
           Copy Command
         </button>
         <button
           onClick={closeDeployModal}
           className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
         >
           Close
         </button>
       </div>
       {copiedMessage && (
         <p className="text-green-600 text-sm mt-2">{copiedMessage}</p>
       )}
     </div>
   </div>
 )}

        </div>
    );
  }


};

export default NotebookEditor;