import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaKey, FaGoogle, FaSlack, FaPlus, FaHome } from 'react-icons/fa';
const backendUrl = process.env.REACT_APP_TOOLS_BACKEND_URL || "";

const ConnectAccount = ({ session }) => {
  const [connections, setConnections] = useState([
    { connection_name: 'google', connection_type: 'oauth' },
    { connection_name: 'slack', connection_type: 'api_key' },
  ]);
  const [newConnection, setNewConnection] = useState({ connection_name: '', connection_type: 'api_key' });
  const [apiKey, setApiKey] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // fetchConnections();
  }, []);

  const fetchConnections = async () => {
    try {
      const response = await axios.get(backendUrl + '/connections', {
        headers: { Authorization: `Bearer ${session.access_token}` }
      });
      setConnections(response.data);
    } catch (error) {
      console.error('Error fetching connections:', error);
    }
  };

  const handleConnect = async (connectionName, connectionType) => {
  try {
    const response = await axios.post(
      `${backendUrl}/connect/${connectionType}/${connectionName}`,
      null,
      {
        headers: { Authorization: `Bearer ${session.access_token}` },
      }
    );

    if (response.status === 200) {

      // Open a new window for the proxy URL
      const popup = window.open(response.data.redirect_url, '_blank', 'width=600,height=600');

      if (!popup) {
        console.error('Failed to open popup window.');
        return;
      }

      // Check if the popup has been closed or receives a message
      const checkPopupClosed = setInterval(() => {
        if (popup.closed) {
          clearInterval(checkPopupClosed);
          console.log('Popup closed by user.');
        }
      }, 1000);

      // Listen for messages from the popup
      window.addEventListener('message', (event) => {
        // Ensure the message is coming from the correct origin
        if (event.origin !== backendUrl) {
          return;
        }

        if (event.data.status === 'success') {
          clearInterval(checkPopupClosed);
          popup.close();
          alert("Connection successful")
          console.log('Connection successful and popup closed.');
        }
      });
    }
  } catch (error) {
    console.error('Error connecting:', error);
  }
};

  const handleDisconnect = async (connectionName, connectionType) => {
    try {
      const response = await axios.post(backendUrl + `/disconnect/${connectionType}/${connectionName}`, null, {
        headers: { Authorization: `Bearer ${session.access_token}` }
      });
      if (response.data.success) {
        fetchConnections();
      }
    } catch (error) {
      console.error('Error disconnecting:', error);
    }
  };

  const handleSaveApiKey = async (connectionName) => {
    try {
      const response = await axios.post(`/connect/api_key/${connectionName}`,
        { api_key: apiKey },
        { headers: { Authorization: `Bearer ${session.access_token}` } }
      );
      if (response.data.success) {
        setApiKey('');
        fetchConnections();
      }
    } catch (error) {
      console.error('Error saving API key:', error);
    }
  };

  const handleAddConnection = () => {
    setConnections([...connections, newConnection]);
    setNewConnection({ connection_name: '', connection_type: 'api_key' });
  };

  const handleHomeClick = () => {
    navigate('/notebook');
  };

  const getConnectionIcon = (connectionName) => {
    switch (connectionName.toLowerCase()) {
      case 'google':
        return <FaGoogle />;
      case 'slack':
        return <FaSlack />;
      default:
        return <FaKey />;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-gray-100 shadow-md border-r border-gray-300">
        <div className="p-6">
          <h1 className="text-2xl font-bold text-gray-800">Connections</h1>
        </div>
        <nav className="mt-6">
          <button
            onClick={handleHomeClick}
            className="w-full flex items-center p-4 text-gray-600 hover:bg-purple-100 hover:text-purple-700"
          >
            <FaHome className="mr-4" />
            Go to Notebook
          </button>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1 p-10 overflow-y-auto">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">Connect Accounts</h2>

        {/* Add new connection form */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Add New Connection</h3>
          <div className="flex items-center space-x-4">
            <input
              type="text"
              placeholder="Connection Name"
              value={newConnection.connection_name}
              onChange={(e) => setNewConnection({ ...newConnection, connection_name: e.target.value })}
              className="flex-1 p-2 border rounded"
            />
            <select
              value={newConnection.connection_type}
              onChange={(e) => setNewConnection({ ...newConnection, connection_type: e.target.value })}
              className="p-2 border rounded"
            >
              <option value="api_key">API Key</option>
              <option value="oauth">OAuth</option>
            </select>
            <button
              onClick={handleAddConnection}
              className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 flex items-center"
            >
              <FaPlus className="mr-2" /> Add
            </button>
          </div>
        </div>

        {/* List of connections */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {connections.map((connection) => (
            <div key={connection.connection_name} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-center mb-4">
                {getConnectionIcon(connection.connection_name)}
                <h3 className="text-xl font-semibold text-gray-700 ml-2">{connection.connection_name}</h3>
              </div>
              {connection.connection_type === 'oauth' ? (
                <button
                  onClick={() => handleConnect(connection.connection_name, 'oauth')}
                  className="w-full bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
                >
                  Connect
                </button>
              ) : (
                <div className="space-y-2">
                  <input
                    type="text"
                    placeholder="Enter API Key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                  <button
                    onClick={() => handleSaveApiKey(connection.connection_name)}
                    className="w-full bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
                  >
                    Save Key
                  </button>
                </div>
              )}
              <button
                onClick={() => handleDisconnect(connection.connection_name, connection.connection_type)}
                className="w-full mt-2 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
              >
                Disconnect
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConnectAccount;